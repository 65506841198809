export const environment = {
  production: false,
  debug: true,
  nombre_aplicacion : "Sura Asistencia",
  filtro_aplicacion : false,
  _ssp : 'i8eKn8r2GU68xkq2G0$nzcA^',
  pass_rut: 'DdyTZMcpN8mmRmyvaWcaks5OmleX5hmD',
  iv_rut: 'zOqZsZXe4EJG6hUA',
  api : {
    "uss_" : "U2FsdGVkX18QE66mjB0pRWbGeKC/RQTFuofJSCXDQTg=",
    "pss_" : "U2FsdGVkX1/PqCqdfomRU8afqbTiy3l08q+4sjX4aUc=",
    "ull_" : "https://dev1.prosys.cl/~mvergara/mw_asistencia_rac/web/rac_webapp/",
  },
  contactdata: {
    telefono: "600 390 1000"
  },
  telefono: "tel: +56222665444",
  gmaps: {
    token: "AIzaSyDe45OzsTPacG05l8N4cdrRpvbzh4JfSf4",
    options: "&libraries=places",
    refresh: 30
  },
  gcaptcha: "6LenmqUZAAAAACkMkKjlM1xqnsHx0FAKqsb3zmpn",
};