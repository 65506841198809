import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, Router } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'vehiculos', loadChildren: './usuario/vehiculos/vehiculos.module#VehiculosPageModule' },
  { path: 'home', loadChildren: './usuario/home/home.module#HomePageModule' },
  {
    path: 'seguimiento', children: [
      { path: '', loadChildren: './asistencia/seguimiento/seguimiento.module#SeguimientoPageModule' },
      { path: ':id', loadChildren: './asistencia/seguimiento/mapa-seguimiento/mapa-seguimiento.module#MapaSeguimientoPageModule' }
    ]
  },
  {
    path: 'asistencia',
    children: [
      { path: '', loadChildren: './usuario/asistencia/asistencia.module#AsistenciaPageModule' },
      { path: 'grua', loadChildren: './asistencia/grua/grua.module#GruaPageModule' },
      {
        path: 'problemas',
        children: [
          { path: '', loadChildren: './asistencia/problemas/problemas.module#ProblemasPageModule' },
          { path: 'bateria', loadChildren: './asistencia/problemas/bateria/bateria.module#BateriaPageModule' },
          { path: 'bencina', loadChildren: './asistencia/problemas/bencina/bencina.module#BencinaPageModule' },
          { path: 'neumatico', loadChildren: './asistencia/problemas/neumatico/neumatico.module#NeumaticoPageModule' },
          { path: 'abrir', loadChildren: './asistencia/problemas/abrir/abrir.module#AbrirPageModule' },
        ]
      },
      { path: 'remplazo-auto', loadChildren: './asistencia/remplazo-auto/remplazo-auto.module#RemplazoAutoPageModule' },
      { path: 'revision-tecnica', loadChildren: './asistencia/revision-tecnica/revision-tecnica.module#RevisionTecnicaPageModule' },
      { path: 'taxi', loadChildren: './asistencia/taxi/taxi.module#TaxiPageModule' },
      { path: 'chofer', loadChildren: './asistencia/chofer/chofer.module#ChoferPageModule' },
      { path: 'revision-viaje', loadChildren: './asistencia/revision-viaje/revision-viaje.module#RevisionViajePageModule' },
      { path: 'mantencion-auto', loadChildren: './asistencia/mantencion-auto/mantencion-auto.module#MantencionAutoPageModule' },
      { path: 'ayuda-infracciones', loadChildren: './asistencia/ayuda-infracciones/ayuda-infracciones.module#AyudaInfraccionesPageModule' },
      { path: 'ayuda-legal', loadChildren: './asistencia/ayuda-legal/ayuda-legal.module#AyudaLegalPageModule' },
      { path: '**', loadChildren: './usuario/home/home.module#HomePageModule' }
    ],
  },
  { path: '**', loadChildren: './usuario/home/home.module#HomePageModule' }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: true, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
    private router: Router
  ) {
    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(window.location.search);
    const tParam = urlParams.get('t');
    if (tParam && !currentUrl.includes('login')) {
      this.router.navigate(['/login'], { queryParams: { t: tParam } });
    }
  }
}
