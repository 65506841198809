import { AES, enc, mode, pad } from 'crypto-js';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
export class CryptoService {
    constructor() {
        this.ssp = environment._ssp;
        this.pass_rut = environment.pass_rut;
        this.iv_rut = environment.iv_rut;
    }
    enc(phrase) {
        return AES.encrypt(phrase, this.ssp);
    }
    dec(phrase) {
        return AES.decrypt(phrase, this.ssp).toString(enc.Utf8).trim();
    }
    dec_rut(phrase) {
        return AES.decrypt(phrase, enc.Utf8.parse(this.pass_rut), {
            iv: enc.Utf8.parse(this.iv_rut),
            mode: mode.CBC,
            padding: pad.Pkcs7
        }).toString(enc.Utf8).trim();
    }
    encJson(obj) {
        let objeto = JSON.stringify(obj);
        return this.enc(objeto);
    }
    decJson(obj) {
        let objeto = this.dec(obj);
        return JSON.parse(objeto);
    }
}
CryptoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CryptoService_Factory() { return new CryptoService(); }, token: CryptoService, providedIn: "root" });
