<ion-content scrollY="true" class="ion-padding patente-alerta">
  <ion-grid>
    <ion-row class="ion-justify-content-center ion-text-center">
      <h1 class="titulo">¡Atención!</h1>
    </ion-row>
    <ion-row class="ion-justify-content-center ion-text-center bajo-titulo">
      Estimado cliente: por favor verifique que el último digito de su patente corresponda con el mes en que solicita su servicio de revisión técnica
    </ion-row>
    <hr>
    <ion-row class="ion-justify-content-center ion-text-center">
      <ion-img src="./assets/images/patente-calendario.png"></ion-img>
    </ion-row>
    <hr>
    <ion-row class="ion-justify-content-center ion-text-center">
      <ion-label>
        Puede <b>Cancelar</b> o <b>Solicitar de todas formas.</b>
      </ion-label>
    </ion-row>
    <hr>
    <ion-row class="ion-justify-content-center">
      <ion-button class="adapt w130 " (click)="cancelar()" color="danger">Cancelar</ion-button>
      <ion-button class="adapt w130 " (click)="solicitar()">Solicitar</ion-button>
    </ion-row>
   
    <hr>
    <ion-row class="ion-justify-content-center ion-text-center necesito-ayuda">
      ¿Necesitas ayuda?
    </ion-row>
    <ion-row class="ion-justify-content-center">
      <ion-button (click)="llamar()">
        <ion-label> <fa-icon slot="start" [icon]="faPhoneAlt"></fa-icon> &#x20; Llámanos - {{telefono}}</ion-label>
      </ion-button>
    </ion-row>
  </ion-grid>
</ion-content>