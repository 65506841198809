import { Injectable } from '@angular/core';
import {AES, enc, mode, pad} from 'crypto-js';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  ssp:string;
  pass_rut:string;
  iv_rut:string;

  constructor() {
    this.ssp = environment._ssp;
    this.pass_rut = environment.pass_rut;
    this.iv_rut = environment.iv_rut;
  }

  enc(phrase:string){
    return AES.encrypt(phrase, this.ssp);
  }

  dec(phrase:string){
    return AES.decrypt(phrase, this.ssp).toString(enc.Utf8).trim();
  }

  dec_rut(phrase:string){
    return AES.decrypt(phrase, enc.Utf8.parse(this.pass_rut), {
      iv: enc.Utf8.parse(this.iv_rut),
      mode: mode.CBC,
      padding: pad.Pkcs7
    }).toString(enc.Utf8).trim();
  }

  encJson(obj:any){
    let objeto = JSON.stringify(obj);
    return this.enc(objeto);
  }

  decJson(obj:string){
    let objeto = this.dec(obj);
    return JSON.parse(objeto);
  }
}